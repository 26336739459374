import React from 'react'
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { ActivityIndicator } from "../../generic";
import { Link, navigate } from "gatsby";
import FuneralItemCard from "../..//funeral/FuneralItemCard"

const GET_GROUPS = gql`{
  groups(limit:1000) {
    id
    name
    products {
      id
      groupProductNumber
    }
  }
}`

function GroupsList() {
  const { data, loading, error } = useQuery(GET_GROUPS)

  if (loading) {
    return <ActivityIndicator />
  }

  console.log(data)

  return (
    <div>
        {data.groups.filter(({ products }) => products && products[0] && products[0].groupProductNumber).map(({ id, name, products }) => 
            <FuneralItemCard  key={id} title={name} description={products[0].groupProductNumber} onClick={() => navigate(`/group-funeral/${products[0].id}`)}/>
        )}
    </div>
  );
}

export default GroupsList;